import React from 'react'
import { useNavigate } from 'react-router-dom'

const HolidayBookingList = () => {

    const navigate = useNavigate()

    const createNewBooking = () => {
        navigate('/findcustomer')
    }

    return (
        <>
            <div className="container-fluid h-100 booking_bg_color p-2">
                <div className="container-fluid card holiday_container h-100 ">
                    <div className="mt-4 heading_color mx-2">
                        <div className='d-flex justify-content-between'>
                            <h3 className='fw-bold float-start'>Existing Bookings</h3>
                            <div className="d-flex justify-content-end ms-lg-3">
                                <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={() => createNewBooking()}>Create New Booking</button>
                            </div>
                        </div>
                    </div>

                    <section className="my-3 mx-2">
                        <form action="" >
                            <div className="text-container">
                                <input
                                    className='form-control search_box'
                                    type="text"
                                    name="Search"
                                    id="searchtext"
                                    placeholder='Search by package name / package code / lead traveler name / travel time / destination / status'
                                />
                                <span className="searchBtn pointer_cursor" id="searchtext" ><i className="fa-solid fa-magnifying-glass"></i></span>
                            </div>
                        </form>
                    </section>

                    <section className='my-4 mx-2'>
                        <div className='holiday_packages pointer_cursor'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fs-5'>Goa Luxury Experience</div>
                                    <div className='text-muted package_details mb-2'>Package Code:000000 | Lead Traveler Name | Travel Time: 12-Feb-2024 | Destination: Goa</div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='mx-2 rounded-pill px-4 py-1 confirmed_package_status'>
                                        Confirmed
                                    </div>
                                    <div className='mx-2'>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </div>
                            <hr className='my-0' />
                        </div>

                        <div className='holiday_packages pointer_cursor'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fs-5'>Goa Luxury Experience</div>
                                    <div className='text-muted package_details mb-2'>Package Code:000000 | Lead Traveler Name | Travel Time: 12-Feb-2024 | Destination: Goa</div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='mx-2 rounded-pill px-4 py-1 pending_package_status'>
                                        Pending
                                    </div>
                                    <div className='mx-2 pointer_cursor'>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </div>
                            <hr className='my-0' />
                        </div>

                        <div className='holiday_packages pointer_cursor'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fs-5'>Goa Luxury Experience</div>
                                    <div className='text-muted package_details mb-2'>Package Code:000000 | Lead Traveler Name | Travel Time: 12-Feb-2024 | Destination: Goa</div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='mx-2 rounded-pill px-4 py-1 cancel_package_status'>
                                        Cancel
                                    </div>
                                    <div className='mx-2 pointer_cursor'>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </div>
                            <hr className='my-0' />
                        </div>

                        <div className='holiday_packages pointer_cursor'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fs-5'>Goa Luxury Experience</div>
                                    <div className='text-muted package_details mb-2'>Package Code:000000 | Lead Traveler Name | Travel Time: 12-Feb-2024 | Destination: Goa</div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='mx-2 rounded-pill px-4 py-1 Pending_Payment_package_status'>
                                        Pending Payment
                                    </div>
                                    <div className='mx-2 pointer_cursor'>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </div>
                            <hr className='my-0' />
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default HolidayBookingList
