import React from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData } from '../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { onlyAlphabets, useSortableData, generatePDF, isValidImage } from '../commonClass/CommonValidation'
import { useRef } from 'react';
import DataTable from 'react-data-table-component';
import { ImageDelete, ImageUpload } from '../../ImageUpload/ImageCommon';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ExportExcel } from '../commonClass/ExportExcel';
import { GetMenuIdFromStorage } from '../commonClass/Menudect';

const Userlist = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [clrshow, setsclshow] = useState(false);
    const [userlist, setUserList] = useState<any>([]);
    const [searchby, setSerachBy] = useState<any>('7');
    const [searchvalue, setSearchValue] = useState('');
    const user = useSelector((store: any) => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const [usertype, setUserType] = useState(3)
    const serachoption = [
        // {
        //     label: 'Sr No',
        //     value: '1'
        // },
        {
            label: 'Name',
            value: '2'
        },
        {
            label: 'Gender',
            value: '3'
        },
        {
            label: 'Department',
            value: '8'
        },
        {
            label: 'Role',
            value: '4'
        },
        {
            label: 'Email',
            value: '5'
        },
        {
            label: 'Mobile Number',
            value: '6'
        },
        {
            label: 'Active',
            value: '8'
        },
        {
            label: 'All',
            value: '7'
        },

    ];
    const getuserdatalist = async () => {
        dispatch(loaderActions.start());
        const usrdata = await getAllData('/auth/v1/getadminuserlist?UserType=' + usertype);
        setUserList(usrdata.data);
        dispatch(loaderActions.end());
    }
    const columns: any = [
        {
            name: 'Sr No.',
            sortable: true,
            float: "left",
            selector: (user: any) => user.sqnNo,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Name',
            sortable: true,
            float: "left",
            selector: (user: any) => user.fullName,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Gender',
            sortable: true,
            float: "left",
            selector: (user: any) => user.gender,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Department',
            sortable: true,
            float: "left",
            selector: (user: any) => user.departmentName,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Role',
            sortable: true,
            float: "left",
            selector: (user: any) => user.roleName,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Email',
            sortable: true,
            float: "left",
            selector: (user: any) => user.userEmail,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Mobile Number',
            sortable: true,
            float: "left",
            selector: (user: any) => user.contactNo,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (user: any) => user.IsActive ? 'Active' : 'InActive'
            // style:  { backgroundColor: 'green' }
        },
        // {
        //     name: 'Created Date',
        //     sortable: true,
        //     float:"left",
        //     // selector: (hotel) => formatDate(hotel.CreatedDate),
        //     // style:  { backgroundColor: 'green' }
        // },
        {
            name: 'Edit',
            // float:"right",
            selector: (user: any) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEdit(user.userMasterID)}></i></button>
            // selector: (hotel) => <i className="fa fa-edit" onClick={() => handleEditMode(hotel.HotelId)}></i>
        },
        // {
        //     name: 'View',
        //     // float:"right",
        //     // selector: hotel => <td style={{ textAlign: "center", width: "5%", cursor: "pointer" }} onClick={() => handleViewMode(hotel.HotelId)}><i className="fa fa-eye"></i></td>
        //     // selector: (hotel) => <i className="fa fa-eye" onClick={() => handleViewMode(hotel.HotelId)}></i>
        // }
    ];
    const handleSearchBy = (e: any) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e: any) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getuserdatalist();
            setsclshow(false)
        }
    }
    const onKeyDownHandler = (e: any) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };
    const handleClickSearchValue = () => {
        if (searchvalue.trim().length !== 0 && searchby > 0) {
            setUserList([]);
            var filteritem = userlist;
            var filterdata;
            // if (searchby === '1') {
            //     filterdata = filteritem?.filter(issec => issec.sqnNo?.toString().toLowerCase().includes(searchvalue.toLowerCase()));
            // }
            if (searchby === '2') {
                filterdata = filteritem?.filter((issec: any) => issec.fullName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter((issec: any) => issec.gender?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter((issec: any) => issec.roleName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter((issec: any) => issec.userEmail?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter((issec: any) => issec.contactNo?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '8') {
                filterdata = filteritem?.filter((issec: any) => issec.departmentName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '8') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter((issec: any) => issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter((issec: any) => issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter((issec: any) => issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter((issec: any) => issec.IsActive === false);
                }
            }
            else if (searchby === '7') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter((issec: any) => issec.fullName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.gender?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.roleName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.userEmail?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.contactNo?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.departmentName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter((issec: any) => issec.fullName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.gender?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.roleName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.userEmail?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.contactNo?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.departmentName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter((issec: any) => issec.fullName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.gender?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.roleName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.userEmail?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.contactNo?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.departmentName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true
                        || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter((issec: any) => issec.fullName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.gender?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.roleName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.userEmail?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.contactNo?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.departmentName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter((issec: any) =>
                        // issec.sqnNo?.toString().toLowerCase().includes(searchvalue.toLowerCase()) ||
                        issec.fullName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.gender?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.roleName?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.userEmail?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.contactNo?.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.departmentName?.toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setUserList(filterdata)
        }
    }
    useEffect(() => {
        getuserdatalist();
    }, [])
    useEffect(() => {
        //check for rights
        let pagelink = user?.find((x: any) => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find((x: any) => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/*');
        }
        user?.filter((x: any) => x.MenuId === pagelink.MenuId).map((obj: any) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);
        });

    }, [user])
    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getuserdatalist();
    }
    const handleOpen = () => {
        navigate('/Useradd', { state: { disabled: false, editdisabled: false, viewtype: 'Add', Id: 0 } });
    }
    const handleEdit = (id: any) => {
        dispatch(loaderActions.start());
        let Id = 0;
        userlist?.map((obj: any) => {
            Id = obj.userMasterID;
        });
        navigate('/Useradd', { state: { disabled: false, editdisabled: true, viewtype: 'Update', Id: id } });
    }
    const PdfExport = () => {
        const columns = [
            'Sr No', 'Name', 'Gender', 'Department', 'Role', 'Email', 'Mobile Number', 'Active'
        ];
        var rows: any = [];

        for (let i = 0; i < userlist.length; i++) {
            var temp = [
                userlist[i].sqnNo,
                userlist[i].fullName,
                userlist[i].gender,
                userlist[i].departmentName,
                userlist[i].roleName,
                userlist[i].userEmail,
                userlist[i].contactNo,
                `${userlist[i].IsActive ? 'Active' : 'InActive'}`,
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "UserList", "User List")
    }
    const ExportToExcel = () => {
        const dispalyname = ['Sr No', 'Name', 'Gender', 'Department', 'Role', 'Email', 'Mobile Number', 'Active'];
        const fields = ['sqnNo', 'fullName', 'gender', 'departmentName', 'roleName', 'userEmail', 'contactNo', 'IsActive'];
        ExportExcel(dispalyname, fields, userlist, "UserList", "UserList.xls");
    }
    return (
        <>
            <div className="container-fluid h-100">
                <div className="container-fluid mt-4 heading_color">
                    <h3>User List</h3>
                </div>
                <div className="container-fluid form_border py-5 my-3">
                    <form action="" >
                        <div className="d-flex justify-content-start ms-lg-3">
                            <button type="button" disabled={!useradd} className="btn btn-primary mx-1" id="btnAdd" onClick={handleOpen} >Create New User</button>
                        </div>
                        <br />
                        <div className="row">
                            <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                                <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                    <Select
                                        className="dropdown"
                                        options={serachoption}
                                        value={serachoption.filter(function (serachoption) {
                                            return serachoption.value === searchby;
                                        })}// set list of the dataalue 
                                        onChange={handleSearchBy} // assign onChange function
                                        // isDisabled={serchdisable ? true : false}
                                        isSearchable={false}

                                    />
                                </div>
                                <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                    <div className='clrbutton'>
                                        <input onKeyDown={onKeyDownHandler} type="text" id="btnSearchText" className="form-control" name="SearchValue" placeholder='Enter keyword'
                                            onChange={handleChangeSearchValue}
                                            value={searchvalue}
                                        // disabled={serchdisable ? true : false} 
                                        />
                                        <button className={`${clrshow === true ? "d-block" : "d-none"}`} onClick={clearsearch} type="reset">&times;</button>
                                    </div>
                                </div>
                                <div className="p-2 text-center">
                                    <button type="button" className="btn btn-primary mx-1" id="btnSearch"
                                        // disabled={serchdisable ? true : false}
                                        onClick={handleClickSearchValue}
                                    >Search</button>
                                </div>
                            </div>

                        </div>
                        <div className="mb-3 row">
                            {/* <div className="col-lg-12">
                            <label for="inputPassword" className=" col-form-label fw-bolder">TAG</label>
                        </div> */}
                            <div className="col-lg-12">
                                <div className="mb-3 row mx-1 form_border py-3">
                                    <div className="col-lg-12 mb-1 table-scrollable user_role">
                                        {userlist &&
                                            <DataTable
                                                columns={columns}
                                                data={userlist}
                                                highlightOnHover
                                                pagination
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
                                                responsive
                                            />
                                        }
                                    </div>
                                    <div>
                                        <button type="button" disabled={!userexport} className="border-0 p-0 bg-body me-1 set_disableclr">
                                            <i className="fa fa-file-pdf-o" style={{ fontSize: "28px", color: "red" }} onClick={PdfExport}></i>
                                        </button>
                                        <button type='button' disabled={!userexport} className='border-0 p-0 bg-body me-1 set_disableclr'><i className="fa fa-file-excel-o" aria-hidden="true" style={{ fontSize: "28px", color: "green" }} onClick={ExportToExcel}></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div >
            </div>
        </>
    )
}

export default Userlist