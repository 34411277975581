import React from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';

const CreateCustomer = () => {
    const navigate = useNavigate()

    const openDatePicker = () => {
        document.getElementById('birthdate')?.click()
    };

    const handlebirthdate = () => {

    }

    const create = () => {
        navigate('/findcustomer')
    }

    return (
        <>
            <div className="container-fluid h-100 booking_bg_color p-2">
                <div className="container-fluid card holiday_container h-100">

                    <div className="container">
                        <div className="row create_customer_container d-flex justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-12 col-12">
                                <h2 className='fw-bold text-center mb-3'>Create Customer</h2>
                                <form action="">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-10">
                                            <div className="my-3">
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    name="fullname"
                                                    id="fullname"
                                                    placeholder='Full Name'
                                                />
                                            </div>
                                            <div className="text-container">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control'
                                                    id='birthdate'
                                                    // selected={departureDate}
                                                    onChange={(e) => handlebirthdate()}
                                                    // disabled={disabled ? true : false}
                                                    placeholderText="Date of Birth"
                                                    maxDate={new Date()}
                                                    autoComplete='off'
                                                />
                                                <span className="example-custom-input calendarBtn pointer_cursor" onClick={(e) => openDatePicker()} id="birthdate" ><i className="fa fa-calendar"></i></span>
                                            </div>

                                            <div className="my-3">
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    placeholder='Email'
                                                />
                                            </div>
                                            <div className="my-3">
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    name="number"
                                                    id="number"
                                                    placeholder='Phone Number'
                                                />
                                            </div>
                                            <div className="my-3">
                                                <button type="button" className="btn btn-primary col-12" id="" onClick={() => create()}>Create</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default CreateCustomer
