import React from 'react';
import { useEffect, useState } from "react";
import "../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { GetMenuIdFromStorage } from '../commonClass/Menudect';
import Select from 'react-select';
import { RoleRightModel } from './Modals/RightsModel';

const Rolerights = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userrolerights, setUserRoleRights] = useState<any>([]);
    const [roleList, setRoleList] = useState<any>([]);
    const [roleId, setRoleId] = useState(0)
    const [toggleAdmin, setToggleRowAdmin] = useState<any>([]);
    const user = useSelector((store: any) => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const [savedata, setDataSave] = useState(new RoleRightModel())
    const [usertype, setUserType] = useState(3)
    const handleExpandAdmin = (e: any) => {
        const doesExist = toggleAdmin.includes(e);
        if (doesExist === false) {
            setToggleRowAdmin((current: any) => [...current, e]);
        }
        else {
            setToggleRowAdmin((oldState: any) => oldState?.filter((item: any) => item !== e));

        }
    }
    const getRoleList = async () => {
        await getAllData('/auth/v1/bindroleslist?UserType=' + usertype).then((roledata) => {
            setRoleList(roledata.data?.map((el: any) => ({
                label: el.label,
                value: el.value
            })))
        })
    }
    const getUserRoleRigths = async () => {
        await getAllData('/auth/v1/binduserrolerights?UserType=' + usertype).then((userdata) => {
            setUserRoleRights(userdata.data);
        })
    }
    const getRoleById = async () => {
        setUserRoleRights([]);
        const roleid = roleId;
        await getAllData('/auth/v1/getusereoledetailbyrole?roleid=' + roleid + "&UserType=" + usertype).then((roledata) => {
            setUserRoleRights(roledata.data);
            setDataSave({ ...savedata, RoleId: roleId, UserRolesRightsList: roledata.data })
            dispatch(loaderActions.end());
        })
        let pagelink = user?.find((x: any) => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find((x: any) => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/*');
        }
        user?.filter((x: any) => x.MenuId === pagelink.MenuId).map((obj: any) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }
    const handleRolechange = (e: any) => {
        // when select the same value from drop-down than "e.value or roleId" doesn't change the value. Therefore, there is no effect in below "useEffect with roleId dependancy".
        // And here we clear the userrolerights in drop-down on change function.
        // setUserRoleRights([]);
        setRoleId(e.value);
    }
    useEffect(() => {
        setUserRoleRights([]);
        dispatch(loaderActions.start());
        getRoleById();
    }, [roleId])


    const handlesavadata = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            let status = false;
            let data: any = false;
            const UserData = await postUsersData("/auth/v1/insertorupdaterolesrights", savedata);
            status = UserData.status;
            data = UserData.data;
            if ((!status && data === null) || (!status && !data)) {
                dispatch({ type: 'ALERTING', message: UserData.message })
                return;
            }
            else {
                if (status && data > 0) {
                    dispatch({ type: 'ALERTING', message: UserData.message })
                    handleClearClick();
                }
                else {
                    dispatch({ type: 'ALERTING', message: UserData.message })
                    handleClearClick();
                    return;
                }
            }
        }

    }
    const handleChangeCheck = (e: any, menuId: any) => {
        if (roleId == 0) {
            // dispatch(alertActions.error('please select Role'));
        }
        else {
            let Vuserroledetail = userrolerights;
            let Rights = Vuserroledetail.filter((t: any) => t.Menu_Id === menuId);
            if (e.target.value === "All") {
                if (e.target.checked) {
                    Rights[0].uRoleAll = true;
                    Rights[0].uRoleView = true;
                    Rights[0].uRoleExport = true;
                    Rights[0].uRoleAdd = true;
                    Rights[0].uRoleEdit = true;
                }
                else {
                    Rights[0].uRoleAll = false;
                    Rights[0].uRoleView = false;
                    Rights[0].uRoleExport = false;
                    Rights[0].uRoleAdd = false;
                    Rights[0].uRoleEdit = false;
                }
            }

            else if (e.target.value === "Add") {
                Rights[0].uRoleAdd = Rights[0].uRoleAdd === true ? false : true;
            }
            else if (e.target.value === "Edit") {
                Rights[0].uRoleEdit = Rights[0].uRoleEdit === true ? false : true;
            }
            else if (e.target.value === "View") {
                Rights[0].uRoleView = Rights[0].uRoleView === true ? false : true;
            }
            else if (e.target.value === "Export") {
                Rights[0].uRoleExport = Rights[0].uRoleExport === true ? false : true;
            }
            if (Rights[0].uRoleView === true && Rights[0].uRoleExport === true && Rights[0].uRoleAdd === true && Rights[0].uRoleEdit === true) {
                Rights[0].uRoleAll = true;
            }
            if (Rights[0].uRoleView != true) {
                Rights[0].uRoleView = Rights[0].uRoleExport || Rights[0].uRoleAdd || Rights[0].uRoleEdit;
            }
            if (!Rights[0].uRoleView || !Rights[0].uRoleExport || !Rights[0].uRoleAdd || !Rights[0].uRoleEdit) {
                Rights[0].uRoleAll = false;
            }
            let fnlUserRight = Vuserroledetail.map((user: any) => {
                if (user.Menu_Id == menuId) {
                    return Rights[0];
                }
                return user;
            })
            Vuserroledetail = fnlUserRight;
            setUserRoleRights(Vuserroledetail);
            setDataSave({ ...savedata, RoleId: roleId, UserRolesRightsList: Vuserroledetail })

        }
    }
    const handleClearClick = () => {
        setRoleId(0);
    }
    useEffect(() => {
        getUserRoleRigths();
        getRoleList();
    }, []);

    return (
        <>
            <div className="container-fluid h-100">
                <div className="container-fluid mt-4 heading_color">
                    <h3>Role Rights</h3>
                </div>
                <div className="container-fluid form_border py-5 my-3">
                    <form action="" >
                        <div className="mb-3 row mx-1">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    {/* {galleryduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {galleryduplicateerrorMessage} </div>} */}
                                    <div className='col-md-6 mb-lg-1'>
                                        <div className='row'>
                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                <label htmlFor="inputPassword6" className="col-form-label required">Select Role</label>
                                            </div>
                                            <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12">
                                                <Select className="dropdown select_dropdown"
                                                    options={roleList}
                                                    value={roleList?.filter(function (roleList: any) {
                                                        return roleList.value === roleId;
                                                    })}
                                                    onChange={handleRolechange}
                                                // isDisabled={disabled ? true : false}
                                                />
                                                {/* {cityerrorMessage && <div className="text-danger"> {cityerrorMessage} </div>} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <div className='overflow-auto'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className='ps-4' scope="col">Title</th>
                                            <th scope="col">All</th>
                                            <th scope="col">View</th>
                                            <th scope="col">Add</th>
                                            <th scope="col">Edit</th>
                                            <th scope="col">Export</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userrolerights && userrolerights?.map((el: any) => {
                                            return (
                                                <>
                                                    {
                                                        (el.MenuType === 0 && el.PageTitle === "Dashboard") ?
                                                            <>
                                                                <tr>
                                                                    <th className='ps-4' style={{ width: "275px" }} scope="row">{el.PageTitle}</th>
                                                                    <td><input className="form-check-input" checked={el.uRoleAll} name='All' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="All" /></td>
                                                                    <td><input className="form-check-input" checked={el.uRoleView} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="View" /></td>
                                                                    <td><input className="form-check-input" checked={el.uRoleAdd} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Add" /></td>
                                                                    <td><input className="form-check-input" checked={el.uRoleEdit} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Edit" /></td>
                                                                    <td><input className="form-check-input" checked={el.uRoleExport} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Export" /></td>
                                                                </tr>
                                                            </> : ''
                                                    }
                                                    {
                                                        (el.MenuType === 1) ?
                                                            <>
                                                                <tr>
                                                                    <th className='admin_btn' style={{ width: "275px" }} onClick={() => handleExpandAdmin(el.ParentId)} scope="row"><i className={`fa fa-angle-down me-1 ${toggleAdmin.filter((val1: any) => (val1 === el.ParentId))?.map((val: any) => val === el.ParentId ? 'rotet' : '')}`} aria-hidden="true"></i>{el.PageTitle}</th>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </> : ''
                                                    }
                                                    {toggleAdmin.filter((val1: any) => (val1 === el.ParentId))?.map((val: any) => {
                                                        return (
                                                            (el.MenuType === 0) ?
                                                                <>
                                                                    <tr>
                                                                        <th className='ps-4' style={{ width: "275px" }} scope="row">{el.PageTitle}</th>
                                                                        <td><input className="form-check-input" checked={el.uRoleAll} name='All' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="All" /></td>
                                                                        <td><input className="form-check-input" checked={el.uRoleView} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="View" /></td>
                                                                        <td><input className="form-check-input" checked={el.uRoleAdd} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Add" /></td>
                                                                        <td><input className="form-check-input" checked={el.uRoleEdit} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Edit" /></td>
                                                                        <td><input className="form-check-input" checked={el.uRoleExport} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Export" /></td>
                                                                    </tr>
                                                                </> : ''
                                                        )
                                                    })}

                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mb-4">
                            {(useradd === true || useredit === true) ?
                                <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handlesavadata} disabled={roleId > 0 ? false : true} >Save</button>
                                :
                                <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handlesavadata} disabled={true} >Save</button>}
                            <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick}>Clear</button>
                        </div>
                    </form >
                </div >
            </div>
        </>
    )
}

export default Rolerights