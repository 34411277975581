import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Switch from 'react-switch';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import Select from 'react-select';

const CustomizedPackageDetail = () => {
    const navigate = useNavigate()
    const [adultCount, setAdultCount] = useState(0)
    const [childCount, setChildCount] = useState(0)
    const [infantCount, setInfantCount] = useState(0)
    const [activeId, setActiveId] = useState(false)

    const [isEdit, setIsEdit] = useState(false)
    const [editTabName, setEditTabName] = useState('')


    const handlePackage = (e: any) => {
        if (e === 'Fixed') {
            navigate('/fixedpackage')
        }
        else {
            navigate('/customizedpackage')
        }
    }

    const handleBackClick = () => {
        navigate(-1)
    }


    const adultcountdecrease = () => {
        if (adultCount > 0) {
            setAdultCount(adultCount - 1)
        }

    }
    const adultcountincrease = () => {
        // if (adultCount < 5) {
        // }
        setAdultCount(adultCount + 1)

    }

    const childcountdecrease = () => {
        if (childCount > 0) {
            setChildCount(childCount - 1)
        }

    }
    const childcountincrease = () => {
        // if (childCount < 5) {
        // }
        setChildCount(childCount + 1)

    }

    const infantcountdecrease = () => {
        if (infantCount > 0) {
            setInfantCount(infantCount - 1)
        }

    }
    const infantcountincrease = () => {
        // if (infantCount < 5) {
        // }
        setInfantCount(infantCount + 1)
    }

    const handleisActive = (e: any) => {
        setActiveId(!activeId)
    }

    const handleProceedForBook = () => {
        navigate('/travelerdetail')
    }

    const openDatePicker = (e: any) => {
        document.getElementById(e)?.click()
    };

    const handlebirthdate = () => {

    }

    const handleEditBtn = (e: any) => {
        // debugger
        // // lightblue_color
        // // day-1-cab-item day-1-flight-item day-1-hotel-item day-1-hotel-item day-1-sightseeing-item day-1-otherservices-item
        // console.log(document.getElementById('day-1-cab-item')?.classList.contains('show'),'document.getElementById(day-1-cab-item)?.classList.contains(show)')
        // setIsEdit(!isEdit)
        // setEditTabName(e)
    }

    return (
        <>

            <div className="container-fluid booking_bg_color p-2">
                <div className="mx-2">
                    <h3 className='lightblue_color fw-bold'>Sumit Aggarwal</h3>
                    <div className='fw-bold mb-2'>DOB: 12-10-1990 | 9876543210 | sumit.aggarwal@gmail.com </div>
                </div>

                <div className='mt-3 tab_section package_list col-xl-4 col-lg-5 col-md-8 col-sm-12'>
                    <ul className="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link border-0 " id="pills-Fixed-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-Fixed" type="button" onClick={() => handlePackage('Fixed')}><strong>Fixed</strong></a>
                        </li>
                        <li className="nav-item" role="presentation" >
                            <a className="nav-link active border-0 " id="pills-Customized-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-Customized" type="button" onClick={() => handlePackage('Customized')}><strong >Customized</strong></a>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="container-fluid h-100 booking_bg_color p-2">

                <div className="container-fluid card">
                    <div className="mx-2">

                        <div className="mt-4 heading_color">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                        <h3 className='text-start'>
                                            <i className="fa-solid fa fa-1.2x fa-angle-left pointer_cursor" onClick={handleBackClick}></i>
                                        </h3>
                                        <h3 className='text-start fw-bolder mx-3 -mt-2n'>Goa Luxury Experience</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="text-container">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                className='form-control'
                                                id='departuredate'
                                                // selected={departureDate}
                                                onChange={(e) => handlebirthdate()}
                                                // disabled={disabled ? true : false}
                                                placeholderText="Date of Departure"
                                                maxDate={new Date()}
                                                autoComplete='off'
                                            />
                                            <span className="example-custom-input calendarBtn pointer_cursor" onClick={(e) => openDatePicker('departuredate')} id="departuredate" ><i className="fa fa-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <section className="my-3">
                            <div className="row">
                                <div className="col-lg-6 col-md-4 col-sm-12 my-2">
                                    <div className='d-flex gap-3 justify-content-lg-start justify-content-md-start justify-content-center align-items-center '>
                                        <div className="lightblue_bg px-2 py-1 rounded">
                                            <div className='text-muted package_details'>Duration</div>
                                            <div className='fw-bold'>3N - 4D</div>
                                        </div>
                                        <div className="lightblue_bg px-2 py-1 rounded">
                                            <div className='text-muted package_details'>Start - End Point</div>
                                            <div className='fw-bold'>Delhi - Goa</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-8 col-sm-12 my-lg-0">
                                    <div className='d-flex gap-3 justify-content-lg-end justify-content-md-end justify-content-center align-items-center'>
                                        <div className="px-2 py-1 rounded">
                                            <div className='fw-bold'>Adult</div>
                                            <div className='counter_btns rounded d-flex justify-content-center align-items-center'>
                                                <button className='btn btn-primary fa-lg me-2 p-0 plus_minus_btn' onClick={() => adultcountdecrease()}>
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                </button>
                                                <div className="">
                                                    {adultCount}
                                                </div>
                                                <button className='btn btn-primary fa-lg ms-2 p-0 plus_minus_btn' onClick={() => adultcountincrease()}>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="px-2 py-1 rounded">
                                            <div className='fw-bold'>Kids</div>
                                            <div className='counter_btns rounded d-flex justify-content-center align-items-center'>
                                                <button className='btn btn-primary fa-lg me-2 p-0 plus_minus_btn' onClick={() => childcountdecrease()}>
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                </button>
                                                <div className="">
                                                    {childCount}
                                                </div>
                                                <button className='btn btn-primary fa-lg ms-2 p-0 plus_minus_btn' onClick={() => childcountincrease()}>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="px-2 py-1 rounded">
                                            <div className='fw-bold'>Infant</div>
                                            <div className='counter_btns rounded d-flex justify-content-center align-items-center'>
                                                <button className='btn btn-primary fa-lg me-2 p-0 plus_minus_btn' onClick={() => infantcountdecrease()}>
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                </button>
                                                <div className="">
                                                    {infantCount}
                                                </div>
                                                <button className='btn btn-primary fa-lg ms-2 p-0 plus_minus_btn' onClick={() => infantcountincrease()}>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="my-3">
                            {/* <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="travel_detail_switch customized_trip_switch">
                                        <Switch
                                            onChange={handleisActive}
                                            checked={activeId}
                                            className="react-switch"
                                            id='customizedtrip'
                                        />
                                    </div>
                                    <div className="mx-2">
                                        <label htmlFor="customizedtrip" className="col-form-label">Convert to Customized Trip</label>
                                    </div>
                                </div>
                            </div> */}
                            <div className="d-flex justify-content-end ms-lg-3">
                                <button type="button" className="btn rounded-pill px-4 py-2 book_now_btn" id="btnAdd" onClick={() => handleProceedForBook()}>Proceed for Booking</button>
                            </div>

                            <hr />
                        </section>


                        <section className="my-3">
                            <div className='row'>
                                <div className="col-lg-6 col-md-4 col-sm-12 my-2">
                                    <div className='d-flex justify-content-lg-start justify-content-md-start justify-content-start align-items-center '>
                                        <h4 className='heading_color text-start fw-bolder mb-0'>Itineraries</h4>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-8 col-sm-12 my-2'>
                                    <div className='d-flex justify-content-lg-end justify-content-md-end justify-content-center align-items-center'>
                                        <div className='fw-bold lightblue_color'> Included: </div> <span className='ms-1 lightblue_color'> Flight | Hotels | Meals | Transfer | Activities | Pick & Drop</span>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="my-3">
                            <div className="row mb-3">

                                <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <label htmlFor="" className='text-muted mb-1'>Pickup Date</label>
                                    </div>
                                    <div className="text-container">
                                        <div className="lightblue_bg p-2 rounded">
                                            <div className='fw-bold'>3N - 4D</div>
                                        </div>
                                        <span className="example-custom-input editbtn pointer_cursor"><i className="fa fa-pen"></i></span>
                                    </div>
                                </div>




                                <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <label htmlFor="" className='text-muted mb-1'>Pickup Time</label>
                                    </div>
                                    <div className="text-container">
                                        <div className="lightblue_bg p-2 rounded">
                                            <div className='fw-bold'>10:00 AM</div>
                                        </div>
                                        <span className="example-custom-input editbtn pointer_cursor"><i className="fa fa-pen"></i></span>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <label htmlFor="" className='text-muted mb-1'>Pickup Point</label>
                                    </div>
                                    <div className="text-container">
                                        <div className="lightblue_bg p-2 rounded">
                                            <div className='fw-bold'>Delhi</div>
                                        </div>
                                        <span className="example-custom-input editbtn pointer_cursor"><i className="fa fa-pen"></i></span>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <label htmlFor="" className='text-muted mb-1'>Drop Point</label>
                                    </div>
                                    <div className="text-container">
                                        <div className="lightblue_bg p-2 rounded">
                                            <div className='fw-bold'>Delhi</div>
                                        </div>
                                        <span className="example-custom-input editbtn pointer_cursor"><i className="fa fa-pen"></i></span>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="my-3 lightblue_bg rounded">
                            <div className='py-3 px-3 customized_itinerary_items'>
                                <h5 className='fw-bolder'>Day 1 | <span className='lightblue_color'>Arrival in North Goa</span></h5>
                                {/* Cab */}
                                <div className=''>
                                    <div className="accordion-item">
                                        <div className='d-flex justify-content-between align-items-center' id='day-1-cab-item-heading'>
                                            <div>
                                                <h6 className=''><span className='fw-bolder'>Cab</span> | <span className=''>Travel Date/Time: 12-Feb-2024 / 10:00AM</span></h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='mx-1 pointer_cursor'>
                                                    <i className="fa-regular fa-trash-can"></i>
                                                </div>
                                                <div className={`mx-1 pointer_cursor  collapsed ${editTabName === 'Cab' ? '' : ''}`} data-bs-toggle="collapse" data-bs-target="#day-1-cab-item" aria-expanded="false" aria-controls="day-1-cab-item" onClick={() => handleEditBtn('Cab')}>
                                                    <i className="fa fa-pen"></i>
                                                </div>
                                                <div className='mx-2 rounded-pill px-4 py-1 confirmed_package_status'>
                                                    Confirmed
                                                </div>
                                                <div className='bg-white p-2'>
                                                    <h6 className='mb-0'>Rs. 1000/00</h6>
                                                    <small className='text-muted'>Net Price / Service Charge</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="day-1-cab-item" className="accordion-collapse collapse" aria-labelledby="day-1-cab-item-heading">
                                            <div className=" my-2">
                                                <div className="col-lg-8 col-md-12 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-8 col-sm-12 mb-2">

                                                            <div className="text-container">
                                                                <DatePicker
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className='form-control'
                                                                    id='traveldate'
                                                                    // selected={departureDate}
                                                                    onChange={(e) => handlebirthdate()}
                                                                    // disabled={disabled ? true : false}
                                                                    placeholderText="Travel Date"
                                                                    maxDate={new Date()}
                                                                    autoComplete='off'
                                                                />
                                                                <span className="example-custom-input calendarBtn pointer_cursor" onClick={(e) => openDatePicker('traveldate')} id="traveldate" ><i className="fa fa-calendar"></i></span>
                                                            </div>

                                                        </div>
                                                        <div className="col-lg-6 col-md-8 col-sm-12 mb-2 customized_timepicker">
                                                            <TimePicker
                                                                id='DepTime'
                                                                className='form-control defo_border'
                                                                // disabled={disabled ? true : false}
                                                                placeholder='Travel Time'
                                                                // value={departureTime}
                                                                format={'HH:mm'}
                                                            // onChange={handleDeparture}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='my-0' />
                                </div>

                                {/* Flight */}
                                <div>
                                    <div className="accordion-item">
                                        <div className='d-flex justify-content-between align-items-center' id='day-1-flight-item-heading'>
                                            <div>
                                                <h6 className=''><span className='fw-bolder'>Flight</span> | <span className=''>Travel Date/Time: 12-Feb-2024 / 10:00PM-2:00PM</span></h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='mx-1 pointer_cursor'>
                                                    <i className="fa-regular fa-trash-can"></i>
                                                </div>
                                                <div className={`mx-1 pointer_cursor collapsed ${editTabName === 'Flight' ? '' : ''}`} data-bs-toggle="collapse" data-bs-target="#day-1-flight-item" aria-expanded="false" aria-controls="day-1-flight-item" onClick={() => handleEditBtn('Flight')}>
                                                    <i className="fa fa-pen"></i>
                                                </div>
                                                <div className='mx-2 rounded-pill px-4 py-1 pending_package_status'>
                                                    Pending
                                                </div>
                                                <div className=' bg-white p-2'>
                                                    <h6 className='mb-0'>Rs. 5000/500</h6>
                                                    <small className='text-muted'>Net Price / Service Charge</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="day-1-flight-item" className="accordion-collapse collapse" aria-labelledby="day-1-flight-item-heading">
                                            <div className=" my-2">
                                                <div className="col-lg-8 col-md-12 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-8 col-sm-12 mb-2">

                                                            <div className="text-container">
                                                                <DatePicker
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className='form-control'
                                                                    id='traveldate'
                                                                    // selected={departureDate}
                                                                    onChange={(e) => handlebirthdate()}
                                                                    // disabled={disabled ? true : false}
                                                                    placeholderText="Travel Date"
                                                                    maxDate={new Date()}
                                                                    autoComplete='off'
                                                                />
                                                                <span className="example-custom-input calendarBtn pointer_cursor" onClick={(e) => openDatePicker('traveldate')} id="traveldate" ><i className="fa fa-calendar"></i></span>
                                                            </div>

                                                        </div>
                                                        <div className="col-lg-6 col-md-8 col-sm-12 mb-2 customized_timepicker">
                                                            <TimePicker
                                                                id='DepTime'
                                                                className='form-control defo_border'
                                                                // disabled={disabled ? true : false}
                                                                placeholder='Travel Time'
                                                                // value={departureTime}
                                                                format={'HH:mm'}
                                                            // onChange={handleDeparture}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='my-0' />
                                </div>

                                {/* Hotel */}
                                <div>
                                    <div className="accordion-item">
                                        <div className='d-flex justify-content-between align-items-center' id='day-1-hotel-item-heading'>
                                            <div>
                                                <h6 className=''><span className='fw-bolder'>Hotel</span> | <span className=''>Rain Bay Craft | City:Goa | Check in Check out: 12:00pm-11pm | Meal Plan: abc</span></h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='mx-1 pointer_cursor'>
                                                    <i className="fa-regular fa-trash-can"></i>
                                                </div>
                                                <div className={`mx-1 pointer_cursor  collapsed ${editTabName === 'Hotel' ? '' : ''}`} data-bs-toggle="collapse" data-bs-target="#day-1-hotel-item" aria-expanded="false" aria-controls="day-1-hotel-item" onClick={() => handleEditBtn('Hotel')}>
                                                    <i className="fa fa-pen"></i>
                                                </div>
                                                <div className='mx-2 rounded-pill px-4 py-1 pending_package_status'>
                                                    Pending
                                                </div>
                                                <div className=' bg-white p-2'>
                                                    <h6 className='mb-0'>Rs. 3000/300</h6>
                                                    <small className='text-muted'>Net Price / Service Charge</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="day-1-hotel-item" className="accordion-collapse collapse" aria-labelledby="day-1-hotel-item-heading">
                                            <div className=" my-2">
                                                <div className="col-lg-8 col-md-12 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-8 col-sm-12 mb-2">

                                                            <Select
                                                                className="dropdown"
                                                                // options={serachoption}
                                                                // value={serachoption?.filter(function (serachoption) {
                                                                //     return serachoption.value === searchby;
                                                                // })}// set list of the dataalue 
                                                                // onChange={handleSearchBy} // assign onChange function
                                                                placeholder='Meal Plan'
                                                                isSearchable={true}

                                                            />

                                                        </div>
                                                        <div className="col-lg-6 col-md-8 col-sm-12 mb-2">
                                                            <div className="text-container">
                                                                <DatePicker
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className='form-control'
                                                                    id='traveldate'
                                                                    // selected={departureDate}
                                                                    onChange={(e) => handlebirthdate()}
                                                                    // disabled={disabled ? true : false}
                                                                    placeholderText="Travel Date"
                                                                    maxDate={new Date()}
                                                                    autoComplete='off'
                                                                />
                                                                <span className="example-custom-input calendarBtn pointer_cursor" onClick={(e) => openDatePicker('traveldate')} id="traveldate" ><i className="fa fa-calendar"></i></span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='my-0' />
                                </div>

                                {/* Sight Seeing */}
                                <div>
                                    <div className="accordion-item">
                                        <div className='d-flex justify-content-between align-items-center' id='day-1-sightseeing-item-heading'>
                                            <div>
                                                <h6 className=''><span className='fw-bolder'>Sight Seeing</span> | <span className=''>Beach | Travel Mode: Walking</span></h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='mx-2 pointer_cursor'>
                                                    <i className="fa-regular fa-trash-can"></i>
                                                </div>
                                                <div className={`mx-1 pointer_cursor  collapsed ${editTabName === 'Sight Seeing' ? '' : ''}`} data-bs-toggle="collapse" data-bs-target="#day-1-sightseeing-item" aria-expanded="false" aria-controls="day-1-sightseeing-item" onClick={() => handleEditBtn('Sight Seeing')}>
                                                    <i className="fa fa-pen"></i>
                                                </div>
                                                <div className='mx-1 rounded-pill px-4 py-1 confirmed_package_status'>
                                                    Confirmed
                                                </div>
                                                <div className='bg-white p-2'>
                                                    <h6 className='mb-0'>Rs. 1000/00</h6>
                                                    <small className='text-muted'>Net Price / Service Charge</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="day-1-sightseeing-item" className="accordion-collapse collapse" aria-labelledby="day-1-sightseeing-item-heading">
                                            <div className=" my-2">
                                                <div className="col-lg-8 col-md-12 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-8 col-sm-12 mb-2">

                                                            <input
                                                                className='form-control'
                                                                type="text"
                                                                name=""
                                                                id=""
                                                                placeholder='Destination'
                                                            />

                                                        </div>
                                                        <div className="col-lg-6 col-md-8 col-sm-12 mb-2">
                                                            <Select
                                                                className="dropdown"
                                                                // options={serachoption}
                                                                // value={serachoption?.filter(function (serachoption) {
                                                                //     return serachoption.value === searchby;
                                                                // })}// set list of the dataalue 
                                                                // onChange={handleSearchBy} // assign onChange function
                                                                placeholder='Travel Mode'
                                                                isSearchable={true}

                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='my-0' />
                                </div>

                                {/* Other Services */}
                                <div>
                                    <div className="accordion-item">
                                        <div className='d-flex justify-content-between align-items-center' id='day-1-otherservices-item-heading'>
                                            <div>
                                                <h6 className=''><span className='fw-bolder'>Other Services</span> | <span className=''>Paragliding | Schedule: 12-Feb-2024 / 05:00pm-06:00pm</span></h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='mx-1 pointer_cursor'>
                                                    <i className="fa-regular fa-trash-can"></i>
                                                </div>
                                                <div className={`mx-1 pointer_cursor  collapsed ${editTabName === 'Other Services' ? '' : ''}`} data-bs-toggle="collapse" data-bs-target="#day-1-otherservices-item" aria-expanded="false" aria-controls="day-1-otherservices-item" onClick={() => handleEditBtn('Other Services')}>
                                                    <i className="fa fa-pen"></i>
                                                </div>
                                                <div className='mx-2 rounded-pill px-4 py-1 pending_package_status'>
                                                    Pending
                                                </div>
                                                <div className=' bg-white p-2'>
                                                    <h6 className='mb-0'>Rs. 200/00</h6>
                                                    <small className='text-muted'>Net Price / Service Charge</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="day-1-otherservices-item" className="accordion-collapse collapse" aria-labelledby="day-1-otherservices-item-heading">
                                            <div className=" my-2">
                                                <div className="col-lg-8 col-md-12 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-8 col-sm-12 mb-2">
                                                            <input
                                                                className='form-control'
                                                                type="text"
                                                                name=""
                                                                id=""
                                                                placeholder='Service Name'
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-8 col-sm-12 mb-2">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-8 col-sm-12 mb-2">

                                                                    <div className="text-container">
                                                                        <DatePicker
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className='form-control'
                                                                            id='otherservicesdate'
                                                                            // selected={departureDate}
                                                                            onChange={(e) => handlebirthdate()}
                                                                            // disabled={disabled ? true : false}
                                                                            placeholderText="Date"
                                                                            maxDate={new Date()}
                                                                            autoComplete='off'
                                                                        />
                                                                        <span className="example-custom-input calendarBtn pointer_cursor" onClick={(e) => openDatePicker('otherservicesdate')} ><i className="fa fa-calendar"></i></span>
                                                                    </div>

                                                                </div>
                                                                <div className="col-lg-6 col-md-8 col-sm-12 mb-2 customized_timepicker">
                                                                    <TimePicker
                                                                        id='DepTime'
                                                                        className='form-control defo_border'
                                                                        // disabled={disabled ? true : false}
                                                                        placeholder='Time'
                                                                        // value={departureTime}
                                                                        format={'HH:mm'}
                                                                    // onChange={handleDeparture}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <hr className='my-0' /> */}
                                </div>
                            </div>

                        </section>
                        <section className="my-3">
                            <div className='row'>
                                <div className="col-lg-6 col-md-4 col-sm-12 my-2">
                                    <div className='d-flex justify-content-lg-start justify-content-md-start justify-content-start align-items-center '>
                                        <h4 className='heading_color text-start fw-bolder mb-0'>Inclusion</h4>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="my-3 lightblue_bg rounded">
                            <div className='p-3'>
                                <ul className='list_styled mb-0'>
                                    <li>Daily breakfast and dinner in the Hotel (Breakfast except on the day of arrival.)</li>
                                    <li>Accommodation on Single/Double/Twin sharing basis or as specified in itinerary.</li>
                                    <li>Exclusive Non-a/c vehicle for transfers & sightseeing. (Vehicle will not be at their disposal it will be available to the guest as per itinerary only (point-to-point basis)).</li>
                                    <li>All toll taxes, Fuel charges, Parking Fees as per itinerary</li>
                                    <li>All Covid-19 safety protocols followed.</li>
                                </ul>
                            </div>
                        </section>
                        <section className="my-3">
                            <div className='row'>
                                <div className="col-lg-6 col-md-4 col-sm-12 my-2">
                                    <div className='d-flex justify-content-lg-start justify-content-md-start justify-content-start align-items-center '>
                                        <h4 className='heading_color text-start fw-bolder mb-0'>Exclusions</h4>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="my-3 lightblue_bg rounded">
                            <div className='p-3'>
                                <ul className='list_styled mb-0'>
                                    <li>Items of Personal nature such as laundry, table drinks, telephone bills, tips to room boys, drivers, guides, personal clothing including sleeping bags etc.</li>
                                    <li>Any air fare, train fare tickets.</li>
                                    <li>Room Heater</li>
                                    <li>Monument entrance fee & camera fees.</li>
                                    <li>New year & Christmas supplement charge.</li>
                                    <li>Any Adventure Activities like Safari, Rides, Surfing, and Paragliding etc.</li>
                                    <li>Airport taxes and fuel surcharges, which are subject to change without notice. Any meals (other than specified in the inclusions), drinks, tips, expenses of personal nature</li>
                                    <li>Anything not mentioned in the “Cost Includes” column</li>
                                </ul>
                            </div>
                        </section>
                        <section className="my-3">
                            <div className='row'>
                                <div className="col-lg-6 col-md-4 col-sm-12 my-2">
                                    <div className='d-flex justify-content-lg-start justify-content-md-start justify-content-start align-items-center '>
                                        <h4 className='heading_color text-start fw-bolder mb-0'>Policies</h4>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="my-3">
                            <div className="accordion fixed_according" id="accordionPanelsStayOpenExample">
                                <div className='row g-4'>
                                    <div className='col-6'>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                    Cancellation Policy
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                                <div className="accordion-body">
                                                    <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                    Payment Policy
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                                <div className="accordion-body">
                                                    <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                    Important Information
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                                <div className="accordion-body">
                                                    <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomizedPackageDetail
