import React from 'react'
import { useNavigate } from 'react-router-dom'

const FindCustomer = () => {

    const navigate = useNavigate()

    const createCustomer = () => {
        navigate('/createcustomer')
    }

    const fixedAndCustomizedPackage = () => {
        // navigate('/fixedandcustomizedpackage')
        navigate('/fixedpackage')
    }

    return (
        <>
            <div className="container-fluid h-100 booking_bg_color p-2">
                <div className="container-fluid card ">
                    <div className="mt-4 heading_color mx-2">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                <h3 className='fw-bold float-start'>Find Customer</h3>
                            </div>
                        </div>
                    </div>

                    <section className="my-3 mx-2">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                <form action="" >
                                    <div className="text-container">
                                        <input
                                            className='form-control search_box'
                                            type="text"
                                            name="customer"
                                            id="searchcustomer"
                                            placeholder='Find Customer'
                                        />
                                        <span className="searchBtn pointer_cursor" id="searchcustomer" ><i className="fa-solid fa-magnifying-glass"></i></span>
                                    </div>
                                    <div className='float-end my-1'>
                                        <span className='text-muted'> No match found? </span> <span className='newCustomerBtn pointer_cursor' onClick={() => createCustomer()}> Create New Customer </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="container-fluid card mt-2">
                    <div className="my-3 mx-2">
                        <div className='d-flex justify-content-between'>
                            <h4 className='fw-bold float-start'>Search Result</h4>
                        </div>

                        <div className="mt-4">
                            <div className='holiday_packages pointer_cursor' onClick={() => fixedAndCustomizedPackage()}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <div className=''>Sumit Aggarwal</div>
                                        <div className='text-muted package_details mb-2'>DOB: 12-10-1990 | 9876543210 | sumit.aggarwal@gmail.com </div>
                                    </div>
                                    <div className='mx-2'>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                                <hr className='my-0' />
                            </div>

                            <div className='holiday_packages pointer_cursor'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <div className=''>Sumit Arora</div>
                                        <div className='text-muted package_details mb-2'>DOB: 12-10-1990 | 9876543210 | sumit.aggarwal@gmail.com </div>
                                    </div>
                                    <div className='mx-2'>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                                <hr className='my-0' />
                            </div>

                            <div className='holiday_packages pointer_cursor'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <div className=''>Sumit Makhija</div>
                                        <div className='text-muted package_details mb-2'>DOB: 12-10-1990 | 9876543210 | sumit.aggarwal@gmail.com </div>
                                    </div>
                                    <div className='mx-2'>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                                <hr className='my-0' />
                            </div>
                            <div className='holiday_packages pointer_cursor'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <div className=''>Sumit Kapoor</div>
                                        <div className='text-muted package_details mb-2'>DOB: 12-10-1990 | 9876543210 | sumit.aggarwal@gmail.com </div>
                                    </div>
                                    <div className='mx-2'>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                                <hr className='my-0' />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default FindCustomer
