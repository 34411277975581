import React from 'react'

const ProgressBar = (props: any) => {
    return (
        <>
            <div className="progress_bar">
                <div className={`progress-step first ${window.location.pathname === "/travelerdetail" ? 'is-active' : ''}`}>
                    <div className="step-count"></div>
                    <div className="step-description mt-1">Traveler Details</div>
                </div>
                <div className={`progress-step ${window.location.pathname === "/bookingsummary" ? 'is-active' : ''}`}>
                    <div className="step-count"></div>
                    <div className="step-description mt-1">Booking Summary</div>
                </div>
                <div className={`progress-step last ${window.location.pathname === "/payment" ? 'is-active' : ''}`}>
                    <div className="step-count"></div>
                    <div className="step-description mt-1">Payment</div>
                </div>
            </div>
            <div className='container'>
            </div>
        </>
    )
}

export default ProgressBar