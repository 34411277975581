import React from 'react'
import ProgressBar from './ProgressBar'

const BookingProcess = ({ child }: any) => {
    return (
        <>
            <div className="container-fluid h-100 booking_bg_color p-2">

                <div className="mx-2">
                    <h3 className='lightblue_color fw-bold'>Sumit Aggarwal</h3>
                    <div className='fw-bold mb-2'>DOB: 12-10-1990 | 9876543210 | sumit.aggarwal@gmail.com </div>
                </div>
                <div className="container-fluid card">
                    <div className="mx-2">
                        <div className="my-5">
                            <ProgressBar />
                        </div>
                        <hr />

                        {child}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BookingProcess
