import React, { useState } from 'react'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Switch from 'react-switch';
import { useNavigate } from 'react-router-dom';
import BookingProcess from './BookingProcess';

const TravelerDetail = () => {

    const navigate = useNavigate()
    const [leadOneActiveId, setLeadOneActiveId] = useState(false);
    const [leadTwoActiveId, setLeadTwoActiveId] = useState(false);

    const openDatePicker = () => {
        document.getElementById('birthdate')?.click()
    };

    const handlebirthdate = () => {

    }

    const handleleadoneisActive = (e: any) => {
        setLeadOneActiveId(!leadOneActiveId)
        setLeadTwoActiveId(leadTwoActiveId === true ? false : leadTwoActiveId)
    }
    const handleleadtwoisActive = (e: any) => {
        setLeadOneActiveId(leadOneActiveId === true ? false : leadOneActiveId)
        setLeadTwoActiveId(!leadTwoActiveId)
    }

    const bookNow = () => {
        navigate('/bookingsummary')
    }

    const handleBackClick = () => {
        navigate(-1)
    }

    return (
        <>
            <BookingProcess child={<>

                <div>
                    <div className="mt-4 heading_color">
                        <div className='d-flex justify-content-between'>
                            <div className='justify-content-center d-flex'>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                    <h3 className='text-start'>
                                        <i className="fa-solid fa fa-1.2x fa-angle-left pointer_cursor" onClick={handleBackClick}></i>
                                    </h3>
                                    <h3 className='text-start fw-bolder mx-3 -mt-2n'>Add Traveler Details</h3>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end ms-lg-3">
                                <button type="button" className="btn mx-2 rounded-pill px-4 py-1 book_now_btn" id="btnAdd" onClick={() => bookNow()}>Book Now</button>
                            </div>
                        </div>
                    </div>

                    <section className="my-3">
                        <form action="">

                            <section className='my-4'>

                                <div className="row mb-3">
                                    <div className='d-flex justify-content-between'>
                                        <p className='fs-5 fw-bold'>Traveler 1 (Adult)</p>
                                        <div className="d-flex align-items-center">
                                            <div className="mx-2">
                                                <label htmlFor="lead1" className="col-form-label">Lead</label>
                                            </div>
                                            <div className="travel_detail_switch">
                                                <Switch
                                                    onChange={handleleadoneisActive}
                                                    checked={leadOneActiveId}
                                                    className="react-switch"
                                                    id='lead1'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <Select
                                            className="dropdown"
                                            // options={serachoption}
                                            // value={serachoption?.filter(function (serachoption) {
                                            //     return serachoption.value === searchby;
                                            // })}// set list of the dataalue 
                                            // onChange={handleSearchBy} // assign onChange function
                                            placeholder='Salutation (Mr./Miss/Mrs.)'
                                            isSearchable={true}

                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            placeholder='First Name'
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            placeholder='Last Name'
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <div className="text-container">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                className='form-control'
                                                id='birthdate'
                                                // selected={departureDate}
                                                onChange={(e) => handlebirthdate()}
                                                // disabled={disabled ? true : false}
                                                placeholderText="Date of Birth"
                                                maxDate={new Date()}
                                                autoComplete='off'
                                            />
                                            <span className="example-custom-input calendarBtn pointer_cursor" onClick={(e) => openDatePicker()} id="birthdate" ><i className="fa fa-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className='d-flex justify-content-between'>
                                        <p className='fs-5 fw-bold'>Traveler 2 (Adult)</p>
                                        <div className="d-flex align-items-center">
                                            <div className="mx-2">
                                                <label htmlFor="lead2" className="col-form-label">Lead</label>
                                            </div>
                                            <div className="">
                                                <Switch
                                                    onChange={handleleadtwoisActive}
                                                    checked={leadTwoActiveId}
                                                    className="react-switch"
                                                    id='lead2'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <Select
                                            className="dropdown"
                                            // options={serachoption}
                                            // value={serachoption?.filter(function (serachoption) {
                                            //     return serachoption.value === searchby;
                                            // })}// set list of the dataalue 
                                            // onChange={handleSearchBy} // assign onChange function
                                            placeholder='Salutation (Mr./Miss/Mrs.)'
                                            isSearchable={true}

                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            placeholder='First Name'
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            placeholder='Last Name'
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <div className="text-container">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                className='form-control'
                                                id='birthdate'
                                                // selected={departureDate}
                                                onChange={(e) => handlebirthdate()}
                                                // disabled={disabled ? true : false}
                                                placeholderText="Date of Birth"
                                                maxDate={new Date()}
                                                autoComplete='off'
                                            />
                                            <span className="example-custom-input calendarBtn pointer_cursor" onClick={(e) => openDatePicker()} id="birthdate" ><i className="fa fa-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className='d-flex justify-content-between'>
                                        <p className='fs-5 fw-bold'>Traveler 3 (Child)</p>
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <Select
                                            className="dropdown"
                                            // options={serachoption}
                                            // value={serachoption?.filter(function (serachoption) {
                                            //     return serachoption.value === searchby;
                                            // })}// set list of the dataalue 
                                            // onChange={handleSearchBy} // assign onChange function
                                            placeholder='Salutation (Mr./Miss/Mrs.)'
                                            isSearchable={true}

                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            placeholder='First Name'
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            placeholder='Last Name'
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <div className="text-container">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                className='form-control'
                                                id='birthdate'
                                                // selected={departureDate}
                                                onChange={(e) => handlebirthdate()}
                                                // disabled={disabled ? true : false}
                                                placeholderText="Date of Birth"
                                                maxDate={new Date()}
                                                autoComplete='off'
                                            />
                                            <span className="example-custom-input calendarBtn pointer_cursor" onClick={(e) => openDatePicker()} id="birthdate" ><i className="fa fa-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className='d-flex justify-content-between'>
                                        <p className='fs-5 fw-bold'>Traveler 4 (Infant)</p>
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <Select
                                            className="dropdown"
                                            // options={serachoption}
                                            // value={serachoption?.filter(function (serachoption) {
                                            //     return serachoption.value === searchby;
                                            // })}// set list of the dataalue 
                                            // onChange={handleSearchBy} // assign onChange function
                                            placeholder='Salutation (Mr./Miss/Mrs.)'
                                            isSearchable={true}

                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            placeholder='First Name'
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            placeholder='Last Name'
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 mb-1">
                                        <div className="text-container">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                className='form-control'
                                                id='birthdate'
                                                // selected={departureDate}
                                                onChange={(e) => handlebirthdate()}
                                                // disabled={disabled ? true : false}
                                                placeholderText="Date of Birth"
                                                maxDate={new Date()}
                                                autoComplete='off'
                                            />
                                            <span className="example-custom-input calendarBtn pointer_cursor" onClick={(e) => openDatePicker()} id="birthdate" ><i className="fa fa-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                            </section>

                            <section className='my-4'>

                                <div className="row mb-3">
                                    <div className='d-flex justify-content-between'>
                                        <p className='fs-5 fw-bold'>Lead Details</p>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="mobile"
                                            id="mobile"
                                            placeholder='Mobile / Phone Number'
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="email"
                                            id="email"
                                            placeholder='Email'
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            placeholder='PAN Number'
                                        />
                                    </div>
                                </div>

                                <hr />

                            </section>

                            <section className='my-4'>

                                <div className="row mb-3">
                                    <div className='d-flex justify-content-between'>
                                        <p className='fs-5 fw-bold'>GSTIN Details</p>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                                        <Select
                                            className="dropdown"
                                            // options={serachoption}
                                            // value={serachoption?.filter(function (serachoption) {
                                            //     return serachoption.value === searchby;
                                            // })}// set list of the dataalue 
                                            // onChange={handleSearchBy} // assign onChange function
                                            placeholder='City'
                                            isSearchable={true}

                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="state"
                                            id="state"
                                            placeholder='GST State'
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                                        <textarea
                                            className='form-control'
                                            name="address"
                                            id="address"
                                            rows={1}
                                            placeholder='Address'
                                        />
                                    </div>
                                </div>

                                <hr />

                            </section>

                            <section className='my-4'>

                                <div className="row mb-3">
                                    <div className='d-flex justify-content-between'>
                                        <p className='fs-5 fw-bold'>Special Request</p>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <textarea
                                            className='form-control'
                                            name="specialrequest"
                                            id="specialrequest"
                                            rows={2}
                                            placeholder='Enter Special Request'
                                        />
                                    </div>
                                </div>

                                <hr />

                            </section>

                            <section className='my-4'>

                                <div className="row mb-3">
                                    <div className=''>
                                        <p className='fs-5 fw-bold'>Disclaimer</p>
                                    </div>
                                    <div className="px-2">
                                        <div className="lightblue_bg px-3 py-1 rounded">
                                            <div className="form-check my-2">
                                                <input className="form-check-input" type="checkbox" value="" id="disclaimer" />
                                                <label className="form-check-label" htmlFor="disclaimer">
                                                    I understand and accept the above T&C as begin fair, reasonable and binding.
                                                    Also, I agree to the price package details and name of the passenger shown above.
                                                    In case any itinerary or price discount is not mentioned above.
                                                    ETM is not liable to provide the same.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className=''>
                                        <p className='fs-5 fw-bold'>Lead Consent</p>
                                    </div>
                                    <div className="px-2">
                                        <div className="lightblue_bg px-3 py-1 rounded">
                                            <div className="form-check my-2">
                                                <input className="form-check-input" type="checkbox" value="" id="leadconsent" />
                                                <label className="form-check-label" htmlFor="leadconsent">
                                                    I am willing to be contacted by ETM and its contracted partners before my date of travel,
                                                    for sale and promotion of certain travel-related add-on products.
                                                    I understand that for this ETM would have to share my personal information like contact details, date of traveled.
                                                    I hereby allow ETM to share this and allow its contracted partners to contact me for sale of certain travel-related add-on products.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </section>

                        </form>
                    </section>
                </div>

            </>} />

        </>
    )
}

export default TravelerDetail
