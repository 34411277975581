import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import FixedAndCustomizedPackage from './FixedAndCustomizedPackage';
import { useNavigate } from 'react-router-dom';

const CustomizedPackage = () => {

    const navigate = useNavigate()

    const [toggler, setToogler] = useState(false);

    const openDatePicker = () => {
        document.getElementById('departuredate')?.click()
    };

    const handlebirthdate = () => {

    }

    const handlePackage = (e: any) => {
        if (e === 'Fixed') {
            navigate('/fixedpackage')
        }
        else {
            navigate('/customizedpackage')
        }
    }

    const packageDetail = () => {
        navigate('/customizedpackagedetail')
    }

    return (
        <>
            <div>

                <div className="container-fluid booking_bg_color p-2">
                    <div className="mx-2">
                        <h3 className='lightblue_color fw-bold'>Sumit Aggarwal</h3>
                        <div className='fw-bold mb-2'>DOB: 12-10-1990 | 9876543210 | sumit.aggarwal@gmail.com </div>
                    </div>

                    <div className='mt-3 tab_section package_list col-xl-4 col-lg-5 col-md-8 col-sm-12'>
                        <ul className="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link border-0" id="pills-Fixed-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-Fixed" type="button" onClick={() => handlePackage('Fixed')}><strong>Fixed</strong></a>
                            </li>
                            <li className="nav-item" role="presentation" >
                                <a className="nav-link active border-0 " id="pills-Customized-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-Customized" type="button" onClick={() => handlePackage('Customized')}><strong >Customized</strong></a>
                            </li>
                        </ul>
                    </div>

                </div>

                <div className="container-fluid h-100 booking_bg_color p-2">
                    <div className="container-fluid card mb-2">
                        <div className="mt-4 heading_color mx-2">
                            <div className="d-flex justify-content-center">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                    <h3 className='fw-bold float-start'>Find Package</h3>
                                </div>
                            </div>
                        </div>

                        <section className="my-3 mx-2">
                            <div className="d-flex justify-content-center">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                    <form action="" >
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12 mb-1">
                                                <input
                                                    className='form-control search_box'
                                                    type="text"
                                                    name="customer"
                                                    id="searchcustomer"
                                                    placeholder='Search Package Name / Destination'
                                                />
                                                {/* <div className="text-container">
                                            <span className="searchBtn pointer_cursor" id="searchcustomer" ><i className="fa-solid fa-magnifying-glass"></i></span>
                                        </div> */}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <input
                                                    className='form-control search_box'
                                                    type="text"
                                                    name="customer"
                                                    id="searchcustomer"
                                                    placeholder='City of Departure'
                                                />
                                                <div className="d-flex justify-content-end ms-lg-3 mt-3">
                                                    <button type="button" className="btn btn-primary mx-1" id="btnAdd">Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className='d-flex position-relative'>
                        <div className={`p-4 card ${!toggler ? 'hide_section' : ' me-2 mainsidebar'}`}>
                            <div className='row'>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className='row'>
                                        <div className='col-11'>
                                            <h4 className='fw-bold float-start'>Search Results</h4>
                                        </div>
                                        <div className='col-1'>
                                            <button className={`border-0 float-end bg-body ${!toggler ? 'd-block' : 'd-none'}`} type="button" onClick={() => setToogler(!toggler)}>
                                                <i className="fa-solid fa-sliders"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 p-2">
                                    <div className='card p-2 pointer_cursor' onClick={() => packageDetail()}>
                                        <div className='card-header bg-body'>
                                            <div className='row'>
                                                <div className='col-lg-9 col-md-9 col-sm-12 col-12 mb-2 mb-lg-0 mb-md-0'>
                                                    <div className='row'>
                                                        <div className='w-100'>
                                                            <h5 className='fw-bold float-start mb-0'>Goa Luxury Experience</h5>
                                                        </div>
                                                        <div className='w-100'>
                                                            <small>2 Night South Goa  | 1N North Goa</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-3 col-sm-3 col-6 text-end my-auto'>
                                                    <p className='p-0 border rounded-pill text-center mb-0 night_color'>3 Night</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <small className='small_color'>Fights | Hotels | Meals | Transfer | Activities | Pick & Drop</small>
                                        </div>
                                        <div className='card-footer bg-footer bg-body'>
                                            <div className='row'>
                                                <div className='col-lg-9 col-md-8 col-sm-9'>
                                                    <div className="row">
                                                        <div>
                                                            <small>Start From</small>
                                                        </div>
                                                        <div>
                                                            <h5 className='fw-bolder float-start mb-0'><i className="fa-solid fa-indian-rupee-sign me-1"></i>39999</h5>
                                                        </div>
                                                        <div>
                                                            <small>For 2 person</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-4 col-sm-3 text-end my-auto'>
                                                    <button className='btn btn_view rounded-pill' type='button'>View</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`p-4 card ${toggler ? 'filtersidebar d-block' : 'filterhide_section d-none'}`}>
                            <div className='card-header bg-body pb-3'>
                                <div className='row'>
                                    <div className='d-flex align-items-center col-10'>
                                        <i className="fa-solid fa-sliders me-2"></i>
                                        <h5 className='mb-0 fw-bolder'>
                                            Filter
                                        </h5>
                                    </div>
                                    <div className='col-2'>
                                        <button className='border-0 bg-body ' type="button" onClick={() => setToogler(!toggler)}><i className="fa-solid fa-xmark"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className='card-header bg-body'>
                                <h6 className='mb-0 fw-bolder'>Package Name</h6>
                            </div>
                            <div className='card-header bg-body'>
                                <h6 className='mb-0 fw-bolder'>Category</h6>
                            </div>
                            <div className='card-header bg-body'>
                                <h6 className='mb-0 fw-bolder'>Budget</h6>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}

export default CustomizedPackage