import React from 'react'
import BookingProcess from './BookingProcess'
import { useNavigate } from 'react-router-dom'

const BookingSummary = () => {
    const navigate = useNavigate()

    const handleBackClick = () => {
        navigate(-1)
    }

    const proceedToPay = () => {
        // navigate('/payment')
    }

    return (
        <>
            <BookingProcess child={<>

                <div>

                    <div className="mt-4 heading_color">
                        <div className='d-flex justify-content-between'>
                            <div className='justify-content-center d-flex'>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                    <h3 className='text-start'>
                                        <i className="fa-solid fa fa-1.2x fa-angle-left pointer_cursor" onClick={handleBackClick}></i>
                                    </h3>
                                    <h3 className='text-start fw-bolder mx-3 -mt-2n'>Booking Summary</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="my-3">

                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                                <div className="lightblue_bg h-100 rounded">
                                    <div className="px-4 py-3">
                                        <div className="">
                                            <p className='fw-bold fs-4'>Traveler Details</p>
                                        </div>
                                        <div className='fw-bold fs-5 mb-2'>
                                            <span>Traveler 1</span> | <span className='lightblue_color'>Name | Gender | Adult</span>
                                        </div>
                                        <div className='fw-bold fs-5 mb-2'>
                                            <span>Traveler 2</span> | <span className='lightblue_color'>Name | Gender | Adult</span>
                                        </div>
                                        <div className='fw-bold fs-5 mb-2'>
                                            <span>Traveler 3</span> | <span className='lightblue_color'>Name | Gender | Child</span>
                                        </div>
                                        <div className='fw-bold fs-5 mb-2'>
                                            <span>Traveler 4</span> | <span className='lightblue_color'>Name | Gender | Infant</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                                <div className="lightorange_bg h-100 rounded">
                                    <div className="px-4 py-3">
                                        <div className="">
                                            <p className='fw-bold fs-4'>Payment Breakage</p>
                                        </div>

                                        <section className='mb-3'>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className=' fs-5 mb-2'>
                                                    <span>Total Cost</span>
                                                </div>
                                                <div className='fw-bold fs-5 mb-2'>
                                                    <span>50,000</span>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className=' fs-5 mb-2'>
                                                    <span>Service fees</span>
                                                </div>
                                                <div className='fw-bold fs-5 mb-2'>
                                                    <span>500</span>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className=' fs-5 mb-2'>
                                                    <span>Markup @5%</span>
                                                </div>
                                                <div className='fw-bold fs-5 mb-2'>
                                                    <span>5,000</span>
                                                </div>
                                            </div>
                                            <hr />
                                        </section>

                                        <section className='mb-3'>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='fw-bold fs-5 mb-2'>
                                                    <span>Selling Price</span>
                                                </div>
                                                <div className='fw-bold fs-5 mb-2'>
                                                    <span>55,000</span>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className=' fs-5 mb-2'>
                                                    <span>GST @5%</span>
                                                </div>
                                                <div className='fw-bold fs-5 mb-2'>
                                                    <span>2,775</span>
                                                </div>
                                            </div>
                                            <hr />
                                        </section>

                                        <section className='mb-3'>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='fw-bold fs-5 mb-2'>
                                                    <span>Payable Amount</span>
                                                </div>
                                                <div className='fw-bold fs-5 mb-2'>
                                                    <span>58,275</span>
                                                </div>
                                            </div>
                                        </section>

                                        <section className='mb-3'>
                                            <form action="" >
                                                <input
                                                    className='form-control search_box'
                                                    type="text"
                                                    name="Search"
                                                    id="searchtext"
                                                    placeholder='Add Promo Code'
                                                />
                                            </form>
                                        </section>

                                        <div className="d-flex justify-content-end ms-lg-3">
                                            <button type="button" className="btn mx-2 rounded-pill px-5 py-2 book_now_btn" id="btnAdd" onClick={() => proceedToPay()}>Proceed to Pay</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lightblue_bg h-100 rounded">
                            <div className="px-4 py-3">
                                <div className="mb-4">
                                    <p className='fw-bold fs-4'>Package Itinerary</p>
                                </div>

                                <div className='mb-4'>
                                    <div className='fw-bold fs-5 mb-2'>
                                        <span>Day 1</span> | <span className='lightblue_color'>Arrival in North Goa</span>
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Cab</div>
                                            <div className='text-muted package_details'>Date: 12-Feb-2024 </div>
                                            <div className='text-muted package_details'>Time: 10:00 AM </div>
                                        </div>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Flight</div>
                                            <div className='text-muted package_details'>Date: 12-Feb-2024 </div>
                                            <div className='text-muted package_details'>Time: 12:00 PM - 02:00 PM </div>
                                        </div>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Hotel | Rainbow bay Crafts</div>
                                            <div className='text-muted package_details'>Check in - Check out: 12:00 PM - 11:00 AM </div>
                                            <div className='text-muted package_details'>Room Type: Delux X 1 </div>
                                            <div className='text-muted package_details'>Meal Plan: abc </div>
                                        </div>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Sight Seeing</div>
                                            <div className='text-muted package_details'>Location </div>
                                            <div className='text-muted package_details'>Mode of transport </div>
                                        </div>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Other Service</div>
                                            <div className='text-muted package_details'>Name of service </div>
                                            <div className='text-muted package_details'>Date: 12-Feb-2024 </div>
                                            <div className='text-muted package_details'>05:00 PM - 06:00 PM </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='mb-4'>
                                    <div className='fw-bold fs-5 mb-2'>
                                        <span>Day 2</span> | <span className='lightblue_color'>Stay in North Goa</span>
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Cab</div>
                                            <div className='text-muted package_details'>Date: 12-Feb-2024 </div>
                                            <div className='text-muted package_details'>Time: 10:00 AM </div>
                                        </div>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Hotel | Rainbow bay Crafts</div>
                                            <div className='text-muted package_details'>Check in - Check out: 12:00 PM - 11:00 AM </div>
                                            <div className='text-muted package_details'>Room Type: Delux X 1 </div>
                                            <div className='text-muted package_details'>Meal Plan: abc </div>
                                        </div>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Sight Seeing</div>
                                            <div className='text-muted package_details'>Location </div>
                                            <div className='text-muted package_details'>Mode of transport </div>
                                        </div>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Other Service</div>
                                            <div className='text-muted package_details'>Name of service </div>
                                            <div className='text-muted package_details'>Date: 12-Feb-2024 </div>
                                            <div className='text-muted package_details'>05:00 PM - 06:00 PM </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='mb-4'>
                                    <div className='fw-bold fs-5 mb-2'>
                                        <span>Day 3</span> | <span className='lightblue_color'>Back to Delhi</span>
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Sight Seeing</div>
                                            <div className='text-muted package_details'>Location </div>
                                            <div className='text-muted package_details'>Mode of transport </div>
                                        </div>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Other Service</div>
                                            <div className='text-muted package_details'>Name of service </div>
                                            <div className='text-muted package_details'>Date: 12-Feb-2024 </div>
                                            <div className='text-muted package_details'>05:00 PM - 06:00 PM </div>
                                        </div>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Flight</div>
                                            <div className='text-muted package_details'>Date: 12-Feb-2024 </div>
                                            <div className='text-muted package_details'>Time: 12:00 PM - 02:00 PM </div>
                                        </div>
                                        <div className="white_bg px-2 py-1 rounded">
                                            <div className='fw-bold mb-2'>Cab</div>
                                            <div className='text-muted package_details'>Date: 12-Feb-2024 </div>
                                            <div className='text-muted package_details'>Time: 10:00 AM </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>

            </>} />
        </>
    )
}

export default BookingSummary
