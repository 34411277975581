import React, { useState } from 'react'
import FixedPackage from './FixedPackage';
import CustomizedPackage from './CustomizedPackage';
import { useNavigate } from 'react-router-dom';

const FixedAndCustomizedPackage = () => {

    const navigate = useNavigate()

    const handlePackage = (e: any) => {
        if (e === 'Fixed') {
            navigate('/fixedpackage')
        }
        else {
            navigate('/customizedpackage')
        }
    }

    return (
        <>
            <div className="container-fluid h-100 booking_bg_color p-2">
                <div className="mx-2">
                    <h3 className='text_color fw-bold'>Sumit Aggarwal</h3>
                    <div className='fw-bold mb-2'>DOB: 12-10-1990 | 9876543210 | sumit.aggarwal@gmail.com </div>
                </div>

                {/* <div className='mt-3 tab_section package_list col-xl-4 col-lg-5 col-md-8 col-sm-12'>
                    <ul className="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active border-0 " id="pills-Fixed-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-Fixed" type="button" role="tab" aria-controls="pills-about"
                                aria-selected="true"><strong>Fixed</strong></a>
                        </li>
                        <li className="nav-item" role="presentation" >
                            <a className="nav-link border-0 " id="pills-Customized-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-Customized" type="button" role="tab" aria-controls="pills-tips"
                                aria-selected="false"><strong >Customized</strong></a>
                        </li>
                    </ul>
                </div> */}

                {/* <div className="container-fluid px-0 mt-2">
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-Fixed" role="tabpanel"
                            aria-labelledby="pills-Fixed-tab">
                            <FixedPackage />
                        </div>
                        <div className="tab-pane fade show" id="pills-Customized" role="tabpanel"
                            aria-labelledby="pills-Customized-tab">
                            <CustomizedPackage />
                        </div>
                    </div>
                </div> */}

                <div className='mt-3 tab_section package_list col-xl-4 col-lg-5 col-md-8 col-sm-12'>
                    <ul className="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active border-0 " id="pills-Fixed-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-Fixed" type="button" onClick={() => handlePackage('Fixed')}><strong>Fixed</strong></a>
                        </li>
                        <li className="nav-item" role="presentation" >
                            <a className="nav-link border-0 " id="pills-Customized-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-Customized" type="button" onClick={() => handlePackage('Customized')}><strong >Customized</strong></a>
                        </li>
                    </ul>
                </div>

                <div className="container-fluid px-0 mt-2">
                    <div className="tab-content" id="pills-tabContent">

                        {/* {
                            (() => {
                                if (packageName === 1) {
                                    return (
                                        <>
                                            <div className="tab-pane fade show active" id="pills-Fixed" role="tabpanel"
                                                aria-labelledby="pills-Fixed-tab">
                                                <FixedPackage />
                                            </div>
                                        </>
                                    )
                                }
                                else {
                                    return (
                                        <>
                                            <div className="tab-pane fade show" id="pills-Customized" role="tabpanel"
                                                aria-labelledby="pills-Customized-tab">
                                                <CustomizedPackage />
                                            </div>
                                        </>
                                    )
                                }
                            })()
                        } */}

                        {/* <div className="tab-pane fade show active" id="pills-Fixed" role="tabpanel"
                            aria-labelledby="pills-Fixed-tab">
                            <FixedPackage />
                        </div>
                        <div className="tab-pane fade show" id="pills-Customized" role="tabpanel"
                            aria-labelledby="pills-Customized-tab">
                            <CustomizedPackage />
                        </div> */}
                    </div>
                </div>

            </div>
        </>
    )
}

export default FixedAndCustomizedPackage
